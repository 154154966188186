require('slick-carousel')

$(function(){
  $('.slick-home').slick({
    arrows: true,
    slidesToShow: 3,
    centerMode: true,
    prevArrow: $('.res-prev') ,
    nextArrow: $('.res-next'),
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [{

      breakpoint: 992,
      settings: {
        slidesToShow: 1
      }
    }
  ]
  });

  $('.slick-beach').slick({
    arrows: true,
    slidesToShow: 3,
    prevArrow: $('.res-prev1') ,
    nextArrow: $('.res-next1'),
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [{

      breakpoint: 992,
      settings: {
        slidesToShow: 1
      }
    }
  ]
  });

  $('.slick-residencial').slick({
    arrows: true,
    slidesToShow: 3,
    prevArrow: $('.res-prev') ,
    nextArrow: $('.res-next'),
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [{

      breakpoint: 576,
      settings: {
        slidesToShow: 1
      }
    }]

  });

  $('.slick-comercial').slick({
    arrows: true,
    slidesToShow: 3,
    prevArrow: $('.res-prev1') ,
    nextArrow: $('.res-next1'),
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [{

      breakpoint: 576,
      settings: {
        slidesToShow: 1
      }
    }]
  });

  $('.slick-park').slick({
    arrows: true,
    slidesToShow: 3,
    prevArrow: $('.res-prev') ,
    nextArrow: $('.res-next'),
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [{

      breakpoint: 576,
      settings: {
        slidesToShow: 1
      }
    }]
  });

  $('.slick-lineal-park').slick({
    arrows: true,
    slidesToShow: 3,
    prevArrow: $('.res-prev') ,
    nextArrow: $('.res-next'),
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [{

      breakpoint: 576,
      settings: {
        slidesToShow: 1
      }
    }]
  }); 
});


