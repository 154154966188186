const { Collapse } = require("bootstrap");

$('.open-menu').on('click', function(){
   $('.sidebar').addClass('active');
});

$('.sidebar__menu-close').on('click', function(){
    $('.sidebar').removeClass('active');
 });

 $('#sidebar_parent').on('click', function(){
    $(this).removeClass('active');
 })