require('./bootstrap');
require('venobox');
require('./slickk');
require('./sidebar');
require('./beach-carousel');
require('./eskju.jquery.scrollflow');
window.axios = require('axios');

//preloader
$(window).on('load', function () {
  $("#preloaders").fadeOut(1000);
  $(".title-animation").addClass("animate__backInDown");
  $(".text-info-masterplan").addClass("animate__fadeInLeft");
  $(".text-info-animate").addClass("animate__zoomIn");
});

$("#Btn-brochure").on("click", function () {
  $("#brochure-form").addClass("d-none");
  $("#loading").removeClass("d-none");
});

$(document).ready(function () {
  $('.scroll').click(function () {
    var sectionTo = $(this).attr('href');
    $('html, body').animate({
      scrollTop: $(sectionTo).offset().top
    }, 1000);
  });
});

$(document).ready(function () {
  $('.venobox').venobox();
});

window.formatMoney = function (amount) {
  // Create our number formatter.
  var formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(amount); /* $2,500.00 */
}

window.getSVGContentFromUrl = function (url, callback) {
  // read text from URL location
  var request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.send(null);
  request.onreadystatechange = function () {
    if (request.readyState === 4 && request.status === 200) {
      var type = request.getResponseHeader('Content-Type');
      callback(request.responseText);
    }
  }
}

function available() {
  name = document.getElementById("name-brochure").value;
  email = document.getElementById("email-brochure").value;
  state = document.getElementById("state-brochure").value;
  if (name == "" || email == "" || state == "") {
    document.getElementById("Btn-brochure").disabled = true;
  }
  else {
    document.getElementById("Btn-brochure").disabled = false;
  }
};

document.getElementById("name-brochure").addEventListener("keyup", available);
document.getElementById("email-brochure").addEventListener("keyup", available);
document.getElementById("email-state").addEventListener("change", available);